<template>
  <div>
    <audio id="message_ringtone" src="/incoming_message.mp3" muted></audio>
    <audio
      id="conversation_ringtone"
      src="/incoming_conversation.mp3"
      muted
    ></audio>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixpanel from 'mixpanel-browser'
import { datadogRum } from '@datadog/browser-rum'
import { getToken, onMessage } from 'firebase/messaging'
import moengage from '@moengage/web-sdk'
import { messaging } from '@/plugins/firebase'
import { KB_BASE_URL } from '@/assets/variables/endpoints'
import { chatGPT, mekariAirene } from '@/common/services'
import { setStorage } from '~/utils/storage'
import {
  initializeMqtt,
  getMqttClient,
  reconnectMqttClient,
} from '~/utils/mqtt'
import { state } from '~/store/agent'
import { eventTypeLists, senderTypeLists } from '@/utils/eventNotification.js'
import { validTokenCookie } from '~/utils/general'

const DEFAULT_FCM_INTERVAL_CHECK_MS = 10000

export default {
  data() {
    return {
      fcmUnsub: 0,
      fcmIntervalId: 0,
      configPromise: null,
      mqttStatus: false,
      reconnectAttempts: 0,
      maxReconnectAttempts: 10,
    }
  },
  computed: {
    ...mapState('template', ['credentials']),
    ...mapState('preferences', ['time_to_refresh_token_sso', 'billing_info']),
    ...mapState('layouts', ['appConfig', 'browser_type']),
    ...mapState('organization', ['permissions']),
    ...mapState('mqtt', ['mqttCredential, mqttStatus']),
  },
  watch: {
    billing_info: {
      handler(newVal) {
        if (newVal.status === 'freeze') {
          this.$store.commit('package_usage/billings/UPDATE_MODAL_FREEZE', true)
        }
      },
      immediate: true,
    },
    time_to_refresh_token_sso(newVal, oldVal) {
      if (newVal > oldVal) {
        this.intervalTimeToRefreshToken()
      }
    },
    credentials(newVal) {
      if (newVal[0]) {
        this.integrateMoengage(newVal[0])
      }
    },
    '$route.name': {
      handler() {
        setTimeout(() => {
          if (window.LOU) {
            this.identifyUserByLouAssist()
            if (this.appConfig?.lou_assist?.length) {
              this.appConfig.lou_assist.forEach((lou) => {
                if (window.LOU) {
                  if (this.$route.fullPath === lou.link) {
                    window.LOU.startTour(lou.experience_name)
                  }
                }
              })
            }
          }
        }, 3000)
      },
      immediate: true,
    },
  },
  created() {},
  beforeMount() {
    ;['self_topup', 'company_validation'].forEach((menu) => {
      this.getFeatureFlag(menu)
    })
    this.setBrowserType()
    this.fcmCheck({
      checkLocalStorage: false,
    })
    this.getCredential()
    this.getConfiguration()
    this.intervalFcmCheck()
    if (this.isAdmin()) this.getIntegrationTokenStatus()
    this.getCompanySize()
    this.getChatbotSubscription()
    // this.setMixpanelIdentify()
    this.intervalTimeToRefreshToken()
    if (process.env.BASE_URL === 'https://chat.qontak.com') this.setRUMUser()
  },
  mounted() {
    if (this.permissions?.mqtt_notification) {
      this.initMqtt()
    }
    this.unregistWorker()
    this.onMessageListener()
    this.initFacebookSDK(document, 'script', 'facebook-jssdk')
    this.integrateLouAssist()
    this.getListOfUserDivision()
    // will turn on when the api from crm is ready
    this.getKBInfo()
    this.checkFlaggingScorecardInbox()
    this.setChatbotRollout()
    this.checkExpiredBillingEcommerce()
    this.checkQuotaEcommerce()
  },
  beforeDestroy() {
    clearInterval(this.fcmIntervalId)
    localStorage.removeItem('subscribe_token')
  },
  methods: {
    onMessageIncomingHandler(msg, channel) {
      const type = channel === 'mqtt' ? '🟣' : '🟡'
      if (process.env.HUB_ENVIRONMENT === 'staging') {
        console.log(
          `==================${type}-${channel}-${type}==================: `,
          msg
        )
      }

      // channel MQTT is enabled
      if (this.permissions?.mqtt_notification && this.mqttStatus) {
        if (channel === 'mqtt') {
          for (let i = 0; i < eventTypeLists.length; i++) {
            const el = eventTypeLists[i]
            if (el.name === msg?.data?.event_type && el.mqtt) {
              if (process.env.HUB_ENVIRONMENT === 'staging') {
                console.log(`🟣 eventName: ${el.name}`)
              }
              return this.fcmIncomingHandler(msg)
            }
          }
          for (let i = 0; i < senderTypeLists.length; i++) {
            const el = senderTypeLists[i]
            if (el.name === msg?.data?.sender_type && el.mqtt) {
              if (process.env.HUB_ENVIRONMENT === 'staging') {
                console.log(`🟣 senderType: ${el.name}`)
              }

              return this.fcmIncomingHandler(msg)
            }
          }
        }

        // handle if the data from fcm but mqtt is enabled
        if (channel === 'fcm') {
          for (let i = 0; i < eventTypeLists.length; i++) {
            const el = eventTypeLists[i]
            if (el.name === msg.data?.event_type && el.fcm) {
              if (process.env.HUB_ENVIRONMENT === 'staging') {
                console.log(`🟡 eventName: ${el.name}`)
              }
              return this.fcmIncomingHandler(msg)
            }
          }
          for (let i = 0; i < senderTypeLists.length; i++) {
            const el = senderTypeLists[i]
            if (el.name === msg?.data?.sender_type && el.fcm) {
              if (process.env.HUB_ENVIRONMENT === 'staging') {
                console.log(`🟡 senderType: ${el.name}`)
              }
              return this.fcmIncomingHandler(msg)
            }
          }
        }
      } else {
        // when mqttStatus is false switch to FCM
        console.log('🟡 mqtt is not connected. Switch to FCM: ', msg)
        return this.fcmIncomingHandler(msg)
      }
    },
    async initMqtt() {
      await this.$store
        .dispatch('mqtt/getMqttCredentials')
        .then(async (res) => {
          const isStaging = process.env.HUB_ENVIRONMENT === 'staging'
          const mqttBrokerUrl =
            process.env.MQTT_BROKER_URL ||
            (isStaging
              ? 'wss://vernemq.qontak.net/mqtt/'
              : 'wss://vernemq.qontak.com/mqtt/')

          const mqttOptions = {
            port: 8443,
            connectTimeout: 30 * 1000, // Timeout after 30 seconds
            reconnectPeriod: 5000,
            keepalive: 10, // keep alive 10 seconds
            clientId: res.client_id || state.mqttCredential?.client_id,
            username: res.username || state.mqttCredential?.username,
            password: validTokenCookie(),
            protocolVersion:
              parseInt(process.env.MQTT_PROTOCOL_VERSION, 10) || 5,
          }
          await initializeMqtt(mqttBrokerUrl, mqttOptions)

          // Access the MQTT client
          const mqttClient = getMqttClient()
          const orgId = this.$auth.user.organization_id
          const username = this.$auth.user.id

          mqttClient.on('connect', () => {
            console.log('on MQTT connected')
            this.mqttStatus = true
            this.reconnectAttempts = 0
          })

          if (!orgId || !username) {
            console.error('MQTT_ORG_ID and MQTT_USERNAME are required')
            return
          }

          // Subscribe to two topics
          const topic = `org/${orgId}/user/${username}`

          // Subscribe to a topic
          mqttClient.subscribe(topic, (err) => {
            if (!err) {
              console.log('Subscribed to topic: ', topic)
              this.mqttStatus = true
              this.reconnectAttempts = 0
            } else {
              console.error(`Error subscribing to ${topic}: `, err)
            }
          })

          // Listen for messages
          mqttClient.on('message', (topic, message) => {
            const textDecoder = new TextDecoder()
            const jsonString = textDecoder.decode(message)
            let jsonObject = ''
            try {
              jsonObject = JSON.parse(jsonString)
            } catch (error) {
              jsonObject = jsonString
            }
            // Use $set to ensure reactivity when adding a new topic/message
            this.onMessageIncomingHandler(jsonObject, 'mqtt')
          })

          mqttClient.on('error', (err) => {
            console.error('on MQTT error:', err)
          })

          mqttClient.on('close', () => {
            console.warn('on MQTT connection closed')
            this.mqttStatus = false
            if (!this.mqttStatus) {
              this.attemptReconnectMqtt()
            }
          })

          mqttClient.on('offline', (offline) => {
            console.log('on MQTT offline: ', offline)
            this.mqttStatus = false
          })

          mqttClient.on('reconnect', (reconnect) => {
            console.log('on MQTT reconnect: ', reconnect)
          })
        })
    },
    attemptReconnectMqtt() {
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        console.error(
          'Max reconnect attempts reached. Stopping further retries.'
        )
        return
      }

      const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000) // Exponential backoff, capped at 30 seconds
      this.reconnectAttempts++

      console.log(
        `Reconnecting in ${delay / 1000} seconds (Attempt ${
          this.reconnectAttempts
        }/${this.maxReconnectAttempts})`
      )

      setTimeout(() => {
        if (!this.mqttStatus) {
          reconnectMqttClient() // Trigger reconnection
        }
      }, delay)
    },
    setBrowserType() {
      this.$store.commit('layouts/BROWSER_TYPE_UPDATE')
    },
    async intervalFcmCheck() {
      await this.configPromise.catch(() => {})
      const FCM_INTERVAL_MS =
        this.appConfig?.interval_refresh_fcm || DEFAULT_FCM_INTERVAL_CHECK_MS

      this.fcmIntervalId = setInterval(() => {
        this.fcmCheck({
          checkLocalStorage: true,
        })
      }, FCM_INTERVAL_MS)
    },
    fcmCheck(options = {}) {
      if (this.browser_type !== 'Safari') {
        this.$store
          .dispatch('fcm/getBrowserNotificationPermission', {})
          .then((allowance) => {
            if (allowance) {
              this.getFcmRegistrationToken({
                browser: this.browser_type,
                checkLocalStorage: options.checkLocalStorage,
              })
              // this.startTokenRefreshListener()
              this.startOnMessageListener()
            }
          })
      }
    },
    async getFcmRegistrationToken({ checkLocalStorage }) {
      await getToken(messaging, {
        vapidKey: process.env.HUB_FCM_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            /**
             * Only PUT token when init/refresh page, or when token changes during interval
             */
            if (
              checkLocalStorage &&
              currentToken === localStorage.getItem('subscribe_token')
            ) {
              return
            }
            this.$store.dispatch('fcm/getFcmRegistrationToken', currentToken)
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.warn('An error occurred while retrieving token. ', err)
        })
      // this.$store.dispatch('fcm/getFcmRegistrationToken', {})
    },
    startTokenRefreshListener() {
      this.$store.dispatch('fcm/onTokenRefreshListener')
    },
    startOnMessageListener() {
      // this.$fire.messaging.onMessage((payload) => {
      //   this.fcmIncomingHandler(payload)
      // })

      /**
       * Actually calling onMessage multiple times is still safe now, but let's prevent
       */
      if (this.fcmUnsub) {
        return
      }
      this.fcmUnsub = onMessage(messaging, (payload) => {
        if (payload.data === undefined || !payload.data) {
          return
        }
        if (typeof payload.data !== 'object') {
          payload.data = JSON.parse(payload.data)
        }
        this.onMessageIncomingHandler(payload, 'fcm')
        // this.fcmIncomingHandler(payload)
      })
    },
    getIntegrationTokenStatus() {
      this.$store.dispatch('channels/facebook/getConnection', true)
    },
    getCredential() {
      this.$store.dispatch('template/getCredential')
    },
    getConfiguration() {
      this.configPromise = this.$store.dispatch('layouts/getConfiguration')
    },
    unregistWorker() {
      /**
       * @TODO this causes sw deleted on page reload/refresh
       */
      // window.addEventListener('beforeunload', async (event) => {
      //   if (window.navigator && navigator.serviceWorker) {
      //     await navigator.serviceWorker.getRegistrations().then(function(registrations) {
      //       for (const registration of registrations) {
      //         registration.unregister()
      //       }
      //     })
      //   }
      //   return ''
      // })
    },
    onMessageListener() {
      navigator.serviceWorker.addEventListener(
        'message',
        (event) => {
          if (!event.data.mode) {
            if (typeof event.data !== 'object') {
              event.data = JSON.parse(event.data)
            }
            // this.fcmIncomingHandler(event)
            this.onMessageIncomingHandler(event, 'fcm')
          }
        },
        false
      )
    },
    initFacebookSDK(d, s, id) {
      let js = null
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },
    getFeatureFlag(menu) {
      this.$store.dispatch('preferences/getFeatureFlag', menu)
    },
    setMixpanelIdentify() {
      mixpanel.identify(this.$auth.user.sso_id)
      mixpanel.add_group(
        'company',
        this.credentials.length
          ? this.credentials[0].name
          : 'No Company Tracked'
      )
      mixpanel
        .get_group(
          'company',
          this.credentials.length
            ? this.credentials[0].name
            : 'No Company Tracked'
        )
        .set({
          name: this.credentials.length
            ? this.credentials[0].name
            : 'No Company Tracked',
          id: this.credentials.length
            ? this.credentials[0].id
            : 'No Company Tracked',
        })

      mixpanel.set_group(
        'company',
        this.credentials.length
          ? this.credentials[0].name
          : 'No Company Tracked'
      )

      mixpanel.alias(this.$auth.user.email)
      mixpanel.people.set({
        id: this.$auth.user.id,
        $role: this.$auth.user.role,
        $email: this.$auth.user.email,
        sso_id: this.$auth.user.sso_id,
        $name: this.$auth.user.full_name,
        organization_id: this.$auth.user.organization_id,
      })
    },
    getCompanySize() {
      this.$store.dispatch('organization/getCompanySize')
    },
    getSSOClientToken() {
      this.$axios
        .get(`${process.env.BASE_URL}/web/api/metric/token`, {})
        .then((res) => {
          this.$store.commit(
            'layouts/UPDATE_SSO_CLIENT_TOKEN',
            res.data.access_token
          )
        })
    },
    processRefreshToken(isUsingSSO) {
      if (isUsingSSO) {
        let currentTimestampsRefresh = localStorage.getItem('time_to_refresh')
        const intervalToRefresh = setInterval(() => {
          if (new Date().getTime() >= currentTimestampsRefresh) {
            const cookieObj = new URLSearchParams(
              document.cookie.replaceAll('&', '%26').replaceAll('; ', '&')
            )
            const validToken = cookieObj.get('qontak._token.hub')
            const validTokenRefresh = cookieObj.get('qontak._refresh_token.hub')

            const payload = {
              refresh_token: validTokenRefresh,
              grant_type: 'refresh_token',
            }
            const endpoint =
              `${process.env.HUB_SERVICE_URL}/sso_oauth/token`.replace(
                /([^:]\/)\/+/g,
                '$1'
              )

            this.$axios
              .post(endpoint, payload, {
                authorization: validToken,
              })
              .then((resp) => {
                const token = 'bearer ' + resp.data.data.access_token
                const mapRefreshToken = resp.data.data.refresh_token
                const freshToken = resp.data.data.access_token
                this.$auth.setToken('hub', token)
                this.$auth.setRefreshToken('hub', mapRefreshToken)
                this.$store.commit(
                  'preferences/SET_SSO_REFRESH_TIME',
                  (resp.data.data.created_at +
                    (resp.data.data.expires_in - 600)) *
                    1000
                ) // seconds
                this.$store.commit('preferences/SET_NEW_TOKEN', freshToken) // seconds
                setStorage('qontak._token.hub.share', token, {
                  domain: process.env.HOST_URL,
                  maxAge: 3600,
                })
              })
              .catch(() => {})
            clearInterval(intervalToRefresh)
          } else {
            currentTimestampsRefresh = localStorage.getItem('time_to_refresh')
          }
        }, 1000)
      }
    },
    intervalTimeToRefreshToken() {
      this.$axios
        .get(
          `${process.env.HUB_SERVICE_URL}/api/core/v1/client_configs/config`,
          {}
        )
        .then(({ data: res }) => {
          const isUsingSSO =
            process.env.NODE_ENV === 'development'
              ? false
              : res.data?.sso?.is_using_parameter || res.data?.sso?.status
          this.processRefreshToken(isUsingSSO)
        })
        .catch(() => {
          this.processRefreshToken(true)
        })
    },
    setRUMUser() {
      datadogRum.setUser({
        id: this.$auth.user.id,
        role: this.$auth.user.role,
        email: this.$auth.user.email,
        sso_id: this.$auth.user.sso_id,
        name: this.$auth.user.full_name,
        organization_id: this.$auth.user.organization_id,
      })
    },
    integrateLouAssist() {
      this.initLouAssistSDK()
      setTimeout(() => {
        this.identifyUserByLouAssist()
      }, 2000)
    },
    initLouAssistSDK() {
      try {
        let sdk = null
        const louSDK = document.getElementsByTagName('script')[0]
        if (document.getElementById('lou-assist')) {
          return
        }
        sdk = document.createElement('script')
        sdk.id = 'lou-assist'
        sdk.src = `https://run.louassist.com/v2.5.1-m?id=${process.env.LOU_ASSIST_TOKEN}`
        louSDK.parentNode.insertBefore(sdk, louSDK)
      } catch (error) {
        return null
      }
    },
    identifyUserByLouAssist() {
      const ssoId = this.$auth.user.sso_id
      const distributedData = {
        qc_user_id: this.$auth.user.id,
        qc_role: this.$auth.user.role,
        qc_company_id: this.credentials.length
          ? this.credentials[0].company_id
          : 'No Company Tracked',
        sso_company_id: process.env.SSO_CLIENT_ID,
        qc_company_size: this.getOrganizationForMetric()['Company Size'],
        qc_company_name: this.getOrganizationForMetric()['Company Name'],
        qc_company_status: null,
        qc_company_go_live_status: null,
      }
      if (window.LOU) window.LOU.identify(ssoId, distributedData)
    },
    async integrateMoengage(credentials) {
      await moengage.initialize({
        app_id: process.env.MOENGAGE_APP_ID,
        debug_logs: process.env.HUB_ENVIRONMENT === 'production' ? 0 : 0, // clear debug log to 0 again after develop
        enableSPA: true,
      })
      const uniqueId = this.$auth.user.email + ';' + credentials.company_id

      await moengage.add_unique_user_id(uniqueId)
      await moengage.add_first_name(this.$auth.user.full_name)
      await moengage.add_email(this.$auth.user.email)
    },
    getListOfUserDivision() {
      this.$store.dispatch('division/getListOfUserDivision')
    },
    getKBInfo() {
      this.$axios
        .get(`${KB_BASE_URL}/info`, {})
        .then((res) => {
          this.$store.commit('layouts/UPDATE_KB_INFO', res.data.data)
        })
        .catch(() => {
          this.$store.commit('layouts/UPDATE_KB_INFO', {
            kb_client_url: null,
            kb_token: null,
            kb_active: false,
          })
        })
    },
    async getChatbotSubscription() {
      try {
        await chatGPT.getSubscription().then(({ data }) => {
          const features = data.data.features
          const askAireneFeatures = features?.find(
            (item) => item.code === 'CP-QONTAKCHAT-2024-0026'
          )
          const scorecardChatbotSubs = features?.find(
            (item) => item.code === 'CP-QONTAKCHAT-2024-0034'
          )
          const aiSummarizationEnabled = features?.find(
            (item) => item.code === 'QONTAKCHAT-AI-Summarization'
          )
          this.$store.commit('preferences/SET_CHATBOT_SUBSCRIPTION', {
            list: features ?? [],
            airene_resource_enabled: askAireneFeatures?.enabled,
            scorecard_chatbot_subscription: scorecardChatbotSubs?.enabled,
            ai_summarization_enabled: aiSummarizationEnabled?.enabled,
          })
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    checkExpiredBillingEcommerce() {
      this.$store.dispatch(
        'package_usage/billings/checkExpiredEcommerceBilling'
      )
    },
    checkQuotaEcommerce() {
      this.$store.dispatch('package_usage/billings/checkQuotaStoreEcommerce')
    },
    async checkFlaggingScorecardInbox() {
      try {
        const { data } = await chatGPT.getPreferencesScorecardInbox()
        if (data.data?.length > 0) {
          this.$store.commit('organization/UPDATE_PERMISSIONS', {
            name: 'chatbot_scorecard_inbox',
            value: data.data[0].enabled,
          })
        } else {
          this.$store.commit('organization/UPDATE_PERMISSIONS', {
            name: 'chatbot_scorecard_inbox',
            value: true,
          })
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async setChatbotRollout() {
      try {
        await mekariAirene
          .getAireneInformation({
            group_code: 'omnichannel_airene',
            code: 'active_packages',
            enabled: true,
          })
          .then(({ data }) => {
            if (data?.data && data?.data?.length > 0) {
              const billingInfo =
                this.$store.getters['preferences/getBillingInfo']
              const checker = data.data[0].value.packages.includes(
                billingInfo.package_name
              )
              this.$store.commit('organization/UPDATE_PERMISSIONS', {
                name: 'chatbot_rollout_package',
                value: checker ?? false,
              })
            }
          })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
  },
}
</script>
